
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import {
  getManageUserInfoList,
  updateManageUserInfo,
  deleteManageUserInfo,
  addManageUserInfo,
  isEditAuthByCode,
  updateManageUserAuth
} from '@/api/index'
interface deviceDataF {
  loading: boolean

  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  editsOption:any
  sexOption:any
  typeOption:any
  rules:any
  // statusVal:string,  //0:正常 1:冻结 status:'',
  statusOption: Array<any>
  // customerName:string,
  customerData: Array<any>
  editManagerFlag: boolean
  editManagerForm: any
  searchDataAny: any //搜索条件
  editAuthFlag:any
  editAuthForm: any
}

export default defineComponent({
  name: 'ManageTable',
  components: {},
  setup() {
    const manageRef = ref()
    
    
    const validateNewPass = (rule:any, value:any, callback:any) => {
             if (!value) {
                callback(new Error('请输入新密码'))
                return;
            } else if (value.length<6) {
                callback(new Error("密码最少6位"))
                return;
            }else if(/^(\d)\1+$/.test(value)){
                callback(new Error("密码不能全是一样字符"))
                return;
            }
            
            let ascii = value.charCodeAt(0);
            console.log("xxxx0 :  "+ascii);
            var str = value.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)-pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });
            console.log("xxxx1 : "+str +"   "+ascii);
            if (/^(\d)\1+$/.test(str)) 
            {
                callback(new Error("密码不能顺增"))
                return;
            }
            str = value.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)+pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });

            console.log("xxxx : "+str);
            if (/^(\d)\1+$/.test(str)){
                callback(new Error("密码不能顺减"))                  
                return;
            }

            callback()
        }

    const deviceData: deviceDataF = reactive({
      loading: true,
      // statusVal:'',  //0:正常 1:冻结 status:'',
      editManagerFlag: false,
      editAuthFlag:false,
      editAuthForm:{},
      editManagerForm: {},
      searchDataAny: {}, //搜索条件
      statusOption: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '正常',
        },
        {
          value: 1,
          label: '冻结',
        },
      ],
      typeOption: [
        {
          value: 0,
          label: '超级管理员',
        },
        {
          value: 1,
          label: '普通管理员',
        },
      ],
      sexOption: [
        {
          value: 0,
          label: '男',
        },
        {
          value: 1,
          label: '女',
        },
      ],
      editsOption: [
        {
          value: 'all',
          label: '全选',
        },
        {
          value: '101',
          label: 'IMEI管理编辑',
        },
        {
          value: '102',
          label: '用户管理编辑',
        },
        {
          value: '103',
          label: '产品规格管理',
        },
        {
          value: '104',
          label: '管理员管理编辑',
        },
        {
          value: '106',
          label: '报表显示控制表',
        },
        {
          value: '105',
          label: '反馈回复',
        },
        {
          value: '107',
          label: '产品管理',
        },
        {
          value: '108',
          label: '多语言管理',
        },
        {
          value: '109',
          label: '演示IMEI对照管理',
        },
        {
          value: '110',
          label: 'APP版本管理',
        },
        {
          value: '111',
          label: '校验管理',
        },
        {
          value: '112',
          label: '固件版本管理',
        }
      ],
      // customerName:'',
      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
       
        pass: [
                {required: true,validator:validateNewPass,trigger: 'blur'},
                ],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        // authEdits: [{ required: true, message: '请配置权限', trigger: 'blur' }],
      },
    })


    const data = toRefs(deviceData)
    onMounted(() => {
      getList()
    })
    const statusChange = () => {
      console.log('11111')
      deviceData.loading = true
    }

    const editsChange = (values: any) => {
      if (values != null && values.length > 0) {
        //console.log("editsChange:"+values+"----"+values[values.length-1]);
        if (values[values.length - 1] == 'all') {
          let ed = []
          ed.push('all')
          deviceData.editAuthForm.authEdits = ed
        } else {
          if (values[0] == 'all') {
            let ed = []
            ed.push(values[values.length - 1])
            deviceData.editAuthForm.authEdits = ed
          }
        }
      }
    }

    const addManager = () => {
      deviceData.editManagerForm = { isEdit: false }
      deviceData.editManagerFlag = true
    }

 //权限配置
    const permissionsFun = (e: any) => {
      console.log(e)
      deviceData.editAuthForm.id = e.id;
      if (e.edits != null && e.edits!='') {
        deviceData.editAuthForm.authEdits = e.edits.split(',')
      }
      deviceData.editAuthFlag = true;
    }
    
    const editAuthSave = async () => {
        let authEdits = deviceData.editAuthForm.authEdits
        if(authEdits!=null){
            deviceData.editAuthForm.edits = authEdits + ''
        }else{
            delete deviceData.editAuthForm.edits;
        }

        updateManageUserAuth(deviceData.editAuthForm).then((res: any) => {
            let { code } = res
            if (code == 0) {
                ElMessage.success('修改权限成功!')
              deviceData.editAuthFlag = false
              getList()
            } else {
                if(res.errMsg!=null){
                    ElMessage.error(res.errMsg)
                }else{
                    ElMessage.error("修改权限失败")
                }
              
            }
          })
    }

    const editSave = async () => {
      manageRef.value.validate(async (valid: any) => {
        if (valid) {
          let pass = deviceData.editManagerForm.pass
          if (pass) {

            if (pass.length<6) {
                ElMessage.error('密码最少6位');
                return;
            }else if(/^(\d)\1+$/.test(pass)){
                ElMessage.error('密码不能全是一样字符');
                return;
            }
            
            let ascii = pass.charCodeAt(0);
            console.log("xxxx0 :  "+ascii);
            var str = pass.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)-pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });
            console.log("xxxx1 : "+str +"   "+ascii);
            if (/^(\d)\1+$/.test(str)) 
            {
                ElMessage.error('密码不能顺增');
                return;
            }
            str = pass.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)+pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });

            console.log("xxxx : "+str);
            if (/^(\d)\1+$/.test(str)){            
                ElMessage.error('密码不能顺减');
                return;
            }
            
            deviceData.editManagerForm.password = Md5.hashStr(pass)
          }

          let views = deviceData.editManagerForm.views
          if (views == null) {
            deviceData.editManagerForm.views = 'all'
          }

          if (deviceData.editManagerForm.status == null) {
            deviceData.editManagerForm.status = 0
          }

          //let authEdits = deviceData.editManagerForm.authEdits
          //deviceData.editManagerForm.edits = authEdits + ''

          let fun = addManageUserInfo
          if (deviceData.editManagerForm.isEdit) {
            fun = updateManageUserInfo
            delete deviceData.editManagerForm.createTime
          }

          fun(deviceData.editManagerForm).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editManagerFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }
   
    //编辑
    const editorFun = (e: any) => {
      console.log(e)
      deviceData.editManagerForm = {}

      let obj = {}
      Object.assign(obj, e)

      deviceData.editManagerForm = obj
      deviceData.editManagerForm.isEdit = true
      // deviceData.editManagerForm.type =
    //   if (e.edits != null) {
    //     deviceData.editManagerForm.authEdits = e.edits.split(',')
    //   }

      deviceData.editManagerFlag = true
    }
    //停用与启用
    const stopEnableFun = (e: any) => {
      // console.log(e);
      let params = new URLSearchParams()
      params.append('id', e.id)
      if (e.status == 0) {
        params.append('status', '1')
      } else {
        params.append('status', '0')
      }

      updateManageUserInfo(params).then((res: any) => {
        let { code } = res
        if (code == 0) {
          getList()
        } else {
          ElMessage.error(res.errMsg)
        }
      })
    }
    //删除
    const deleteFun = (e: any) => {
      // console.log(e);
      let params = { id: e.id }

      ElMessageBox.confirm(
            '确定删除账号信息吗?',
            '警告',
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
            ).then( async() => {
                await deleteManageUserInfo(params).then((res: any) => {
                        let { code } = res
                        if (code == 0) {
                            getList()
                        } else {
                        ElMessage.error(res.errMsg)
                        }
                    })
            })

      
    }

    const getList = async () => {
      // let params = new URLSearchParams();
      // params.append('length',(deviceData.pageSize as any));
      // params.append('page',(deviceData.currentPage as any));
      // params.append('status',deviceData.statusVal);
      // params.append('keyWord',deviceData.customerName);

      //let params = {length:deviceData.pageSize,page:deviceData.currentPage,status:deviceData.statusVal,keyWord:deviceData.customerName};

      
      let bool = isEditAuthByCode(104);
      if(bool){
        deviceData.loading = false
        // ElMessage.error("无权查询管理员管理功能");
        return;
      }
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getManageUserInfoList(deviceData.searchDataAny).then((res: any) => {
        if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
            // ElMessage.success('加载成功!')
          }
        else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
              deviceData.loading = false
            }else{
                ElMessage.error("加载失败");
              deviceData.loading = false
            }
          
      })
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }

    return {
      ...data,
      addManager,
      manageRef,
      editSave,
      statusChange,
      selectCustomer,
      permissionsFun,
      editorFun,
      stopEnableFun,
      deleteFun,
      handleSizeChange,
      handleCurrentChange,
      editsChange,
      isEditAuthByCode,
      editAuthSave
    }
  },
})
